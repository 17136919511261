import axios from 'axios';
import Vue from 'vue';
import {generateUUID} from '@/utils/helpers';
import UAParser from 'ua-parser-js';
import {LOCATIONS, URL_QUERIES} from '@/const';

const ANALYTICS_SESSION_HASH = 'visaicV3-session';
const DEVICE = 'desktop';
const THREE_HOURS = 1000 * 60 * 60 * 3;

/**
 * Visaic Analytics class - v3;
 */
class VisaicV3Analytics {
  #apiUrl = 'https://api-analytics.yarestream.com';
  #ttl = 1000 * 60 * 60 * 24;
  #ip = null;
  #profileHash = null;
  #userId = null;
  parser = new UAParser();
  #device = this.parser.getDevice().type || DEVICE;
  #apiKey = null;

  /**
   * @param { string|number } userId
   * @param {string} ip
   * @param {string} profile
   */
  init({
    userId,
    ip,
    profile,
    apiKey,
  }) {
    this.userId = userId;
    this.ip = ip;
    this.profileHash = profile;
    this.#apiKey = apiKey;
    if (!this.sessionHash) {
      this.createSession().catch(() => {
      });
    }
  }

  /**
   * @param {string|number|null} id
   */
  set userId(id) {
    this.#userId = id;
  }

  /**
   * @param {string} hash
   */
  set sessionHash(hash) {
    hash && Vue.$storage.set(ANALYTICS_SESSION_HASH, hash, {ttl: this.#ttl});
  }

  /**
   * @param {string} hash
   */
  set profileHash(hash) {
    this.#profileHash = hash;
  }

  /**
   * @param {string} ip
   */
  set ip(ip) {
    this.#ip = ip;
  }

  /**
   * @return {string | null}
   */
  get sessionHash() {
    const hash = Vue.$storage.get(ANALYTICS_SESSION_HASH);
    if (hash instanceof Object && hash.ttl) {
      Vue.$storage.remove(ANALYTICS_SESSION_HASH);
      return null;
    }

    return Vue.$storage.get(ANALYTICS_SESSION_HASH);
  }

  /**
   * @return {string}
   */
  get profileHash() {
    return this.#profileHash;
  }

  /**
   * Getter for the outerTracker property.
   * It retrieves the value of the outerTracker from the Vue's storage.
   * @return {Object|null} - The outerTracker object if it exists in the Vue's storage,
   * otherwise null.
   */
  get outerTracker() {
    return Vue.$storage.get(URL_QUERIES.V3_OUTER_TRACKING);
  }

  /**
   * Setter for the outerTracker property.
   * It sets the value of the outerTracker in the Vue's storage with a time to live (ttl)
   * of three hours.
   * @param {Object|null} value - The value to set for the outerTracker.
   */
  set outerTracker(value) {
    Vue.$storage.set(URL_QUERIES.V3_OUTER_TRACKING, value, {ttl: THREE_HOURS});
  }

  /**
   * @return {Promise<T>}
   */
  getHash() {
    return this.#request({
      url: '/datalake/hash/',
    }).then(({data} = {}) => {
      this.sessionHash = data;
    }).catch(() => {
    });
  }

  /**
   * @param {string} ip
   * @return {Promise<void>}
   */
  async createSession() {
    await this.getHash();
    return this.#request({
      url: '/datalake/session/',
      data: {
        profile: this.profileHash,
        ip: this.#ip,
        session: this.sessionHash,
        device_type: this.#device,
        user_agent: encodeURIComponent(this.parser.getUA()),
        resolution: `${window.screen.availWidth}x${window.screen.availHeight}`,
      },
    }).catch(() => {
    });
  }

  /**
   * @param {string} event
   */
  event({
    event,
    ...req
  }) {
    this.#request({
      url: '/datalake/event/',
      data: {
        profile: this.profileHash,
        session_uuid: this.sessionHash,
        user_id: this.#userId,
        event,
        ...req,
      },
    }).catch(() => {
    });
  }

  /**
   * @param {string} email
   */
  loginEvent(email) {
    this.event({
      event: 'login',
      timestamp: Math.floor(Date.now() / 1000),
      ip: this.#ip,
      email: email,
    });
  }

  /**
   * @param {string|null} uuid
   * @param {string|number} streamId
   * @param {string} type
   * @param {string} event
   * @return {string}
   */
  videoEvent({
    uuid,
    streamId,
    type,
    event = 'first_frame',
  }) {
    const UUID = uuid || generateUUID();
    this.#request({
      url: '/datalake/video/event/',
      data: {
        profile: this.profileHash,
        session_uuid: this.sessionHash,
        video_view_uuid: UUID,
        stream_id: streamId,
        stream_type: type,
        event,
        user_id: this.#userId,
      },
    }).catch(() => {
    });

    return UUID;
  }

  /**
   * @param {string} uuid
   * @param {string|number} streamId
   * @param {string} type
   * @param {number} time
   * @param {string} category
   * @param {string|undefined} sport
   * @param {string|undefined} gender
   * @param {string|undefined} school
   * @param {string|undefined} league
   */
  videoViewingTime({
    uuid,
    streamId,
    type,
    time,
    category,
    sport,
    gender,
    school,
    league,
  }) {
    this.#request({
      url: '/datalake/video/viewing/',
      data: {
        profile: this.profileHash,
        session_uuid: this.sessionHash,
        user_id: this.#userId,
        video_view_uuid: uuid,
        stream_id: streamId,
        stream_type: type,
        viewing_time: time,
        category,
        sport,
        gender,
        school,
        league,
      },
    }).catch(() => {
    });
  };

  /**
   * Tracking home page visiting for logged and unlogged users
   * @param {string} page
   * @param {boolean} logged
   */
  pageVisit({
    page,
  }) {
    this.event({
      event: 'visit_page',
      api_key: this.#apiKey,
      page,
      authorized: String(Number(Boolean(this.#userId))),
      timestamp: Math.floor(Date.now() / 1000),
      outer_referrer: String(document.referrer) || '-',
      from_page: Vue.$storage.get('previous_page_for_analytics') || '-',
    });
  }

  /**
   * @param {object} props
   * @return {Promise<any>}
   */
  async checkParamForRequest(props) {
    if (!this.sessionHash) {
      try {
        await this.createSession(this.#ip);
      } catch (e) {
        return Promise.reject(e);
      }
    }

    return Object.assign(props, {
      session_uuid: this.sessionHash,
    });
  };

  /**
   * send request
   * @param {string} url
   * @param {string} method
   * @param {object} data
   * @return {AxiosPromise}
   */
  async #request({
    url,
    method = 'get',
    data,
  }) {
    if (data) {
      data.origin_url = location.href;
    }

    if (!this.profileHash) {
      return Promise.reject(new Error('No profile key for analytics'));
    }
    if (data?.hasOwnProperty('session_uuid') && !data.session_uuid) {
      data = await this.checkParamForRequest(data);
      if (!data.session_uuid) {
        return Promise.reject(new Error('this request must have valid session_uuid'));
      }
    }

    if (this.outerTracker && data) {
      data[URL_QUERIES.V3_OUTER_TRACKING] = this.outerTracker;
    }
    const parameterizedUrl = new URL(url, this.#apiUrl);

    if (data && method === 'get') {
      const search = parameterizedUrl.searchParams;
      Object.entries(data).forEach(([key, val]) => val && search.set(key, val));
    }

    if (location.hostname === LOCATIONS.LOCALHOST) {
      // console.info('track V3 event', {url: parameterizedUrl.href, data: data, method: method});
      return Promise.reject(new Error('no request from localhost'));
    }
    return axios({
      url: parameterizedUrl.href,
      method: method,
      timeout: 30000,
      data,
    });
  }
}

export default new VisaicV3Analytics();
