const alphaSettings = {
  'footer-background': [20, 50, 70],
  'body-background': [10, 20, 50, 70],
  'body-text-color': [10, 20, 50, 70, 95],
  'header-text-color': [50],
  'header-background': [10, 50, 70],
  'package-text-color': [70]
};
/**
 * return alpha channel for hex color;
 * @param {number} alpha
 * @return {string}
 */
export const getHexAlpha = (alpha) => Math.trunc(255 * alpha / 100).toString(16);


/**
 * Insert color variables from admin to html
 * @param {object} colorObj
 */
export function createColorVariables(colorObj) {
  let stylesString = ``;
  Object.entries(colorObj).forEach(([variable, value]) => {
    if (value) {
      stylesString += `--settings-${variable}:${value};`;
      alphaSettings[variable]?.forEach((alpha) => {
        const alphaHex = getHexAlpha(alpha);
        stylesString += `--settings-${variable}-${alpha}:${value}${alphaHex};`;
      });
    }
  });
  stylesString = `body{${stylesString}}`;

  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.innerHTML = stylesString;

  document.head.appendChild(style);
}
